import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBJ5iU4cLceOuKwTrMpWNdIB8EsnLjiXZU",
  authDomain: "saas-demo-react.firebaseapp.com",
  projectId: "saas-demo-react",
  storageBucket: "saas-demo-react.appspot.com",
  messagingSenderId: "18920587691",
  appId: "1:18920587691:web:aa82631aad075685cfd2ee",
  measurementId: "G-5VJB10180B",
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const functions = getFunctions(firebaseApp);

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}
